import React from "react";
import TodayDate from "./TodayDate";

function Header({ logo, navigation, onCategoryChange }) {
    const handleClick = (name) => {
        onCategoryChange(name);
    };

    return (
        <header>
            <div className="top_box invisible hidden lg:visible lg:flex w-full">
                <div className="self-center w-full text-center font-light">
                    벳투데이가 직접 보증한 사이트의 추천코드만 제공합니다.
                    안전하게 베팅하세요!
                </div>
            </div>

            <nav className="mx-auto flex flex-col max-w-screen-2xl items-center justify-between p-4 lg:px-8">
                <div className="w-full flex justify-between items-center">
                    <div className="bet-logo flex items-center">
                        <a href="/" className="-m-1.5 p-1.5">
                            <img src={logo} alt="logo" className="h-5 lg:h-6" />
                        </a>
                        <span className="px-2 pt-2 text-sm lg:text-base text-gray-400 font-semibold">
                            <TodayDate />
                        </span>
                    </div>

                    <div className="bet-search flex flex-1 justify-end items-center">
                        <div className="flex items-center lg:bg-gray-100 rounded-full p-1">
                            <div className="pl-3">
                                <svg
                                    className="w-5 h-5 text-gray-500"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            </div>
                            <input
                                type="text"
                                className="hidden lg:flex py-2 px-5 rounded-full bg-gray-100 focus:outline-none"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                </div>

                <div className="bet-menu w-full flex justify-center gap-x-4 lg:gap-x-12 mt-6 lg:-mt-8 mb-0 lg:mb-2">
                    {navigation.map((item) => (
                        <button
                            key={item.name}
                            onClick={(e) => {
                                e.preventDefault();
                                handleClick(item.name);
                            }}
                            className={`text-gray-900 ${
                                item.selected ? "selected_menu ..." : ""
                            }`}
                        >
                            {item.name}
                        </button>
                    ))}
                </div>
            </nav>
            <hr className="invisible lg:visible"></hr>
        </header>
    );
}

export default Header;
