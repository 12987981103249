import React from "react";

function TodayDate() {
    const now = new Date();
    const dateStr = `${now.getMonth() + 1}월 ${now.getDate()}일`;

    return (
        <span className="px-2 pt-2 text-sm lg:text-base text-gray-400 font-semibold">
            {dateStr}
        </span>
    );
}

export default TodayDate;
