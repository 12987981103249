import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Home";
import Admin from "./Admin";
import Login from "./Login"; // 로그인 컴포넌트 import
import PrivateRoute from "./PrivateRoute"; // 프라이빗 라우트 컴포넌트 import

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/admin"
                    element={
                        <PrivateRoute>
                            <Admin />
                        </PrivateRoute>
                    }
                />{" "}
                {/* Admin 경로 보호 */}
            </Routes>
        </Router>
    );
}

export default App;
