import React, { useRef, useState, useEffect, memo } from "react";

const BannerItem = memo(({ item }) => (
    <div
        href={item.href}
        className="snap-start flex-none p-2 sm:p-2 lg:p-3 xl:p-3 relative w-9/12 lg:w-1/3 "
    >
        <div className="rounded-2xl bg-white shadow-xl shadow-gray-200">
            <img
                src={item.imageURL}
                alt=""
                className="w-full h-[223px] md:h-[359px] rounded-2xl"
            />
            <div className="absolute m-2 lg:m-3 bottom-0 left-0 right-0 p-2 px-3 lg:p-5 bg-white rounded-b-2xl">
                <div className="flex flex-col">
                    <span className="mt-auto text-sm lg:text-lg font-semibold">
                        {item.name}
                    </span>
                    <span className="mt-auto text-xs lg:text-sm text-gray-500">
                        {item.promotion}
                    </span>
                </div>
            </div>
        </div>
    </div>
));

const ScrollButton = memo(({ direction, onClick, hidden }) => (
    <button
        onClick={onClick}
        className={`invisible lg:visible absolute z-10 top-1/3 -translate-y-1/2 bg-white w-12 h-12 rounded-full shadow-md flex items-center justify-center ${
            direction === "left" ? "left-2" : "right-2"
        } ${hidden ? "hidden" : ""}`}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.8}
            stroke="currentColor"
            className="w-5 h-5"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={
                    direction === "left"
                        ? "M15.75 19.5 8.25 12l7.5-7.5"
                        : "m8.25 4.5 7.5 7.5-7.5 7.5"
                }
            />
        </svg>
    </button>
));

const TodayEvent = ({ title, banner }) => {
    const scrollContainer = useRef(null);
    const [scrollPosition, setScrollPosition] = useState({
        left: true,
        right: false,
    });

    useEffect(() => {
        const scrollContainerCurrent = scrollContainer.current;

        const checkScrollPosition = () => {
            if (!scrollContainerCurrent) return;
            const isAtLeft = scrollContainerCurrent.scrollLeft === 0;
            const isAtRight =
                Math.ceil(scrollContainerCurrent.scrollLeft) +
                    scrollContainerCurrent.offsetWidth >=
                scrollContainerCurrent.scrollWidth - 1;
            setScrollPosition({ left: isAtLeft, right: isAtRight });
        };

        checkScrollPosition();
        scrollContainerCurrent.addEventListener("scroll", checkScrollPosition);
        return () =>
            scrollContainerCurrent.removeEventListener(
                "scroll",
                checkScrollPosition
            );
    }, []);

    const scroll = (direction) => {
        if (scrollContainer.current) {
            const scrollAmount = scrollContainer.current.offsetWidth / 5;
            scrollContainer.current.scrollLeft +=
                direction === "left" ? -scrollAmount : scrollAmount;
        }
    };

    return (
        <div className="py-0 lg:py-4 xl:mx-auto xl:max-w-screen-2xl px-0 lg:px-0">
            <div className="sm:flex sm:items-center sm:justify-between py-1 lg:py-2 px-5 sm:px-6 lg:px-7 xl:px-8">
                <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900">
                    {title}
                </h2>
            </div>

            <div className="relative pl-3 pr-0 lg:px-4">
                <ScrollButton
                    direction="left"
                    onClick={() => scroll("left")}
                    hidden={scrollPosition.left}
                />
                <div
                    ref={scrollContainer}
                    className="snap-x flex overflow-x-auto scroll-smooth scrollbar-hide pb-8"
                >
                    {banner.map((item, index) => (
                        <BannerItem key={index} item={item} />
                    ))}
                </div>
                <ScrollButton
                    direction="right"
                    onClick={() => scroll("right")}
                    hidden={scrollPosition.right}
                />
            </div>
            <hr className="invisible lg:visible mx-8"></hr>
        </div>
    );
};

export default TodayEvent;
