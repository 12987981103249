import React, { useState } from "react";

function ItemsTable({
    items,
    handleEdit,
    deleteItem,
    setIsModalOpen,
    setItemData,
}) {
    const [hoveredItemId, setHoveredItemId] = useState(null);

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(" ");
    };
    return (
        <div>
            <div className="flex flex-row items-center justify-between my-8">
                <div className="bg-gray-300 px-10 py-2 mb-2 -ml-10 rounded-r-xl">
                    <h2 className="text-3xl text-gray-700 font-bold">
                        광고 리스트
                    </h2>
                </div>
                <button
                    onClick={() => {
                        setIsModalOpen(true);
                        setItemData({
                            name: "",
                            type: "",
                            category: "",
                            promotion: "",
                            partner: "",
                            href: "",
                            image: null,
                        });
                    }}
                    className="mb-4 bg-indigo-600 hover:bg-indigo-500 text-white font-bold px-4 py-2 rounded-lg"
                >
                    광고 추가
                </button>
            </div>

            <div className="-mx-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                                Type
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                            >
                                Category
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                            >
                                Promotion
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Partner
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Link
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Image
                            </th>
                            {/* <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Visit
                            </th> */}
                            <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                                <span className="sr-only">Action</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={item._id} className="font-medium">
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-transparent",
                                        "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    )}
                                >
                                    <div className="border-r">{item.type}</div>
                                    {index !== 0 ? (
                                        <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                                    ) : null}
                                </td>
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">
                                        {item.category}
                                    </div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-900 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">{item.name}</div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">
                                        {item.promotion}
                                    </div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">
                                        {item.partner}
                                    </div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">
                                        <a
                                            href={item.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.href}
                                        </a>
                                    </div>
                                </td>
                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">
                                        <img
                                            className={`w-12 ${
                                                hoveredItemId === item._id
                                                    ? "transform scale-150"
                                                    : ""
                                            }`}
                                            src={item.imageURL}
                                            alt={item.name}
                                            onMouseEnter={() =>
                                                setHoveredItemId(item._id)
                                            }
                                            onMouseLeave={() =>
                                                setHoveredItemId(null)
                                            }
                                        />
                                    </div>
                                </td>
                                {/* <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-gray-200",
                                        "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    )}
                                >
                                    <div className="border-r">{item.visit}</div>
                                </td> */}

                                <td
                                    className={classNames(
                                        index === 0
                                            ? ""
                                            : "border-t border-transparent",
                                        "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 w-36 space-x-2"
                                    )}
                                >
                                    <button
                                        type="button"
                                        onClick={() => handleEdit(item)}
                                        className="inline-flex items-center rounded-md bg-yellow-500 hover:bg-yellow-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-inset ring-gray-300 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    >
                                        수정
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => deleteItem(item)}
                                        className="inline-flex items-center rounded-md bg-red-500 hover:bg-red-700 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-inset ring-gray-300 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    >
                                        삭제
                                    </button>
                                    {index !== 0 ? (
                                        <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ItemsTable;
