import React, { useState, useEffect } from "react";
import axios from "axios";

import BannerItem from "./BannerItem";
import ItemSelectionPopup from "./ItemSelectionPopup";
import SiteForm from "./SiteForm";

const API_SERVER = process.env.REACT_APP_API_URL;

function SitesList({ typeOptions }) {
    const [sites, setSites] = useState([]);
    const [newSite, setNewSite] = useState({ title: "", type: "", order: 0 });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentSiteIndex, setCurrentSiteIndex] = useState(null);

    const addItemToSite = async (selectedItems) => {
        if (currentSiteIndex === null) return;

        const currentItems = sites[currentSiteIndex].items;
        const newItemIds = selectedItems.map((item) => item._id);
        const updatedItemIds = Array.from(
            new Set([...currentItems.map((item) => item._id), ...newItemIds])
        );

        try {
            await axios.put(
                `${API_SERVER}/sites/${sites[currentSiteIndex]._id}`,
                {
                    ...sites[currentSiteIndex],
                    items: updatedItemIds,
                }
            );
            const updatedItems = [
                ...currentItems,
                ...selectedItems.filter(
                    (item) => !currentItems.find((ci) => ci._id === item._id)
                ),
            ];
            const updatedSites = sites.map((site, index) =>
                index === currentSiteIndex
                    ? { ...site, items: updatedItems }
                    : site
            );
            setSites(updatedSites);
            setIsPopupOpen(false);
        } catch (error) {
            console.error("아이템을 추가하는 중 오류 발생:", error);
        }
    };

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await axios.get(`${API_SERVER}/sites`);
                setSites(response.data);
            } catch (error) {
                console.error("Error fetching sites:", error);
            }
        };

        fetchSites();
    }, []);

    const addSite = async () => {
        try {
            const { data } = await axios.post(`${API_SERVER}/sites`, newSite);
            setSites(sites.concat(data));
            setNewSite({ title: "", type: "", order: 0 });
        } catch (error) {
            console.error("Error adding site:", error);
        }
    };

    const handleInputChange = (index, field, value) => {
        const newSites = [...sites];
        newSites[index] = { ...newSites[index], [field]: value };
        setSites(newSites);
    };

    const updateSite = async (index) => {
        const site = sites[index];
        try {
            const response = await axios.put(
                `${API_SERVER}/sites/${site._id}`,
                site
            );
            console.log("Update response:", response.data);
        } catch (error) {
            console.error("Error updating site:", error);
        }
    };

    const deleteSite = async (index) => {
        const site = sites[index];
        try {
            await axios.delete(`${API_SERVER}/sites/${site._id}`);
            const updatedSites = [
                ...sites.slice(0, index),
                ...sites.slice(index + 1),
            ];
            setSites(updatedSites);
        } catch (error) {
            console.error("Error deleting site:", error);
        }
    };

    const removeItemFromSite = async (siteIndex, itemIndex) => {
        const siteToUpdate = { ...sites[siteIndex] };
        const updatedItems = siteToUpdate.items.filter(
            (_, index) => index !== itemIndex
        );

        try {
            await axios.put(`${API_SERVER}/sites/${siteToUpdate._id}`, {
                ...siteToUpdate,
                items: updatedItems.map((item) => item._id),
            });

            const updatedSites = [...sites];
            updatedSites[siteIndex] = {
                ...updatedSites[siteIndex],
                items: updatedItems,
            };
            setSites(updatedSites);
        } catch (error) {
            console.error("Error updating site:", error);
        }
    };

    return (
        <div className="mt-16">
            <div className="flex flex-row items-center justify-between">
                <div className="bg-gray-700 p-2 px-10 mb-2 -ml-10 rounded-r-xl">
                    <h2 className="text-3xl text-gray-300 font-bold">
                        화면 리스트
                    </h2>
                </div>
            </div>

            <ul>
                {sites.map((site, siteIndex) => (
                    <li
                        key={`${site._id}-${siteIndex}`}
                        className="mb-4 last:mb-0 border-b pt-8"
                    >
                        <div className="flex flex-row space-x-5 text-xl">
                            <p className="basis-2/5">
                                제목 :
                                <input
                                    type="text"
                                    value={site.title}
                                    onChange={(e) =>
                                        handleInputChange(
                                            siteIndex,
                                            "title",
                                            e.target.value
                                        )
                                    }
                                    className="ml-2 px-2 py-0.5 w-2/3 border-2 border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                            </p>
                            <p className="basis-1/5">
                                타입 :
                                <select
                                    name="type"
                                    value={site.type}
                                    onChange={(e) =>
                                        handleInputChange(
                                            siteIndex,
                                            "type",
                                            e.target.value
                                        )
                                    }
                                    className="ml-2 px-2 py-1 w-2/3 max-w-xs border-2 border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                >
                                    <option value="">
                                        타입을 선택해주세요
                                    </option>
                                    {typeOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </p>
                            <p className="basis-1/5">
                                순서 :
                                <input
                                    type="number"
                                    value={site.order}
                                    onChange={(e) =>
                                        handleInputChange(
                                            siteIndex,
                                            "order",
                                            e.target.value
                                        )
                                    }
                                    className="ml-2 px-2 py-0.5 w-1/3 border-2 border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                />
                            </p>
                            <div className="basis-1/5 flex space-x-2 text-base justify-end font-bold">
                                <button
                                    onClick={() => updateSite(siteIndex)}
                                    className="px-4 py-2 text-white rounded-md bg-yellow-500 hover:bg-yellow-700"
                                >
                                    정보 수정
                                </button>
                                <button
                                    onClick={() => deleteSite(siteIndex)}
                                    className="px-4 py-2 text-white rounded-md bg-red-500 hover:bg-red-600"
                                >
                                    영역 삭제
                                </button>
                                <button
                                    onClick={() => {
                                        setIsPopupOpen(true);
                                        setCurrentSiteIndex(siteIndex);
                                    }}
                                    className="px-4 py-2 text-white rounded-md bg-indigo-600 hover:bg-indigo-500"
                                >
                                    광고 추가
                                </button>
                            </div>
                        </div>

                        <div className="snap-x flex overflow-x-auto scroll-smooth scrollbar-hide py-3">
                            {site.items.map((item, itemIndex) => (
                                <div
                                    key={item._id}
                                    className="relative group cursor-pointer rounded-lg hover:bg-red-100 p-2"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <BannerItem item={item} />

                                    <div
                                        className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100"
                                        onClick={() =>
                                            removeItemFromSite(
                                                siteIndex,
                                                itemIndex
                                            )
                                        }
                                    >
                                        <div className="bg-gray-200 rounded-lg p-1">
                                            <svg
                                                className="h-10 w-10 text-red-500"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={2}
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </li>
                ))}
            </ul>

            <ItemSelectionPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSave={addItemToSite}
                type={sites[currentSiteIndex]?.type}
            />

            <SiteForm
                newSite={newSite}
                setNewSite={setNewSite}
                addSite={addSite}
                typeOptions={typeOptions}
            />
        </div>
    );
}

export default SitesList;
