import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
    const user = localStorage.getItem("user"); // 로그인 상태 확인

    return user ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
