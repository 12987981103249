import React, { useRef, useState, useEffect, memo } from "react";

const BannerItem = memo(({ item }) => (
    <a
        href={item.href}
        target="_blank"
        rel="noreferrer"
        className="snap-start flex-none p-2 sm:p-2 lg:p-3 xl:p-3 relative w-5/12 lg:w-1/5"
    >
        <div className="h-[185px] md:h-[352px] w-auto overflow-hidden rounded-xl md:rounded-3xl">
            <img
                src={item.imageURL}
                alt=""
                className="min-w-full h-full object-cover"
            />
        </div>
        <div className="flex flex-col relative mt-auto p-1 lg:p-2 text-xs lg:text-sm space-y-1">
            <span className="relative mt-auto text-sm lg:text-lg font-semibold">
                {item.name}
            </span>
            <span className="relative mt-auto text-gray-500">
                {item.category}
            </span>
            <span className="relative mt-auto text-gray-500">
                {item.promotion}
            </span>
        </div>
    </a>
));

const ScrollButton = memo(({ direction, onClick, hidden }) => (
    <button
        onClick={onClick}
        className={`invisible lg:visible absolute z-10 top-1/3 -translate-y-1/4 bg-white w-12 h-12 rounded-full shadow-md flex items-center justify-center ${
            direction === "left" ? "left-2" : "right-2"
        } ${hidden ? "hidden" : ""}`}
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2.8}
            stroke="currentColor"
            className="w-5 h-5"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d={
                    direction === "left"
                        ? "M15.75 19.5 8.25 12l7.5-7.5"
                        : "m8.25 4.5 7.5 7.5-7.5 7.5"
                }
            />
        </svg>
    </button>
));

const Partner = ({ title, banner }) => {
    const scrollContainer = useRef(null);
    const [scrollPosition, setScrollPosition] = useState({
        left: true,
        right: false,
    });

    useEffect(() => {
        const scrollContainerCurrent = scrollContainer.current;

        const checkScrollPosition = () => {
            if (!scrollContainerCurrent) return;
            const isAtLeft = scrollContainerCurrent.scrollLeft === 0;
            const isAtRight =
                Math.ceil(scrollContainerCurrent.scrollLeft) +
                    scrollContainerCurrent.offsetWidth >=
                scrollContainerCurrent.scrollWidth - 1;
            setScrollPosition({ left: isAtLeft, right: isAtRight });
        };

        checkScrollPosition();
        scrollContainerCurrent.addEventListener("scroll", checkScrollPosition);
        return () =>
            scrollContainerCurrent.removeEventListener(
                "scroll",
                checkScrollPosition
            );
    }, []);

    const scroll = (direction) => {
        if (scrollContainer.current) {
            const scrollAmount = scrollContainer.current.offsetWidth / 5;
            scrollContainer.current.scrollLeft +=
                direction === "left" ? -scrollAmount : scrollAmount;
        }
    };

    return (
        <div className="py-0 sm:py-4 xl:mx-auto xl:max-w-screen-2xl px-0 lg:px-0">
            <div className="sm:flex sm:items-center sm:justify-between py-1 lg:py-2 px-5 sm:px-6 lg:px-7 xl:px-8">
                <h2 className="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900">
                    {title}
                </h2>
            </div>

            <div className="relative pl-3 pr-0 lg:px-4 mb-8">
                <ScrollButton
                    direction="left"
                    onClick={() => scroll("left")}
                    hidden={scrollPosition.left}
                />
                <div
                    ref={scrollContainer}
                    className="snap-x flex overflow-x-auto scroll-smooth scrollbar-hide"
                >
                    {banner.map((item, index) => (
                        <BannerItem key={index} item={item} />
                    ))}
                </div>
                <ScrollButton
                    direction="right"
                    onClick={() => scroll("right")}
                    hidden={scrollPosition.right}
                />
            </div>
        </div>
    );
};

export default Partner;
