// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top_box {
  height: 60px;
  background-color: #281C6C;
  color: #FFFFFF;
}

.selected_menu {
  color: #281C6C;
  font-weight: bold;
}

.top_header {
  height: 80px;
}

.footer_box {
  /* height: 166px; */
  background-color: #281C6C;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".top_box {\n  height: 60px;\n  background-color: #281C6C;\n  color: #FFFFFF;\n}\n\n.selected_menu {\n  color: #281C6C;\n  font-weight: bold;\n}\n\n.top_header {\n  height: 80px;\n}\n\n.footer_box {\n  /* height: 166px; */\n  background-color: #281C6C;\n  color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
