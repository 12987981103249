import React from "react";

function SiteForm({ newSite, setNewSite, addSite, typeOptions }) {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSite({ ...newSite, [name]: value });
    };

    return (
        <div className="pt-8">
            <h2 className="text-2xl">새로운 영역 추가</h2>
            <div className="flex flex-row space-x-5 text-xl pt-8">
                <p className="basis-2/5">
                    제목:
                    <input
                        name="title"
                        placeholder="Title"
                        value={newSite.title}
                        onChange={handleInputChange}
                        className="ml-2 px-2 w-2/3 border-2 border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </p>
                <p className="basis-1/5">
                    타입:
                    <select
                        name="type"
                        value={newSite.type}
                        onChange={handleInputChange}
                        className="ml-2 px-2 w-2/3 max-w-xs border-2 border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    >
                        <option value="">타입을 선택해주세요</option>
                        {typeOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </p>
                <p className="basis-1/5">
                    순서:
                    <input
                        name="order"
                        type="number"
                        placeholder="Order"
                        value={newSite.order}
                        onChange={handleInputChange}
                        className="ml-2 px-2 w-1/3 border-2 border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                </p>
                <div className="basis-1/5 flex space-x-2 text-base font-bold justify-end">
                    <button
                        onClick={addSite}
                        className="px-4 py-2 text-white rounded-md bg-indigo-600 hover:bg-indigo-500"
                    >
                        영역 추가
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SiteForm;
