import React, { memo } from "react";

const BannerItem = memo(({ item }) => (
    <div className="w-40">
        <img src={item.imageURL} alt="" className="w-auto h-auto" />
        <div className="flex flex-col relative mt-auto py-2 text-xs space-y-1">
            <span className="relative mt-auto text-base font-semibold">
                {item.name}
            </span>
            <span className="relative mt-auto text-gray-500">
                {item.category}
            </span>
            <span className="relative mt-auto text-gray-500">
                {item.promotion}
            </span>
            <span className="relative mt-auto">{item.visit}명 방문</span>
            {item.partner && (
                <div className="pt-1">
                    <span className="inline-flex items-center rounded-md bg-indigo-900 px-2 py-1.5 text-xs lg:text-sm text-white">
                        추천코드: {item.partner}
                    </span>
                </div>
            )}
        </div>
    </div>
));

export default BannerItem;
