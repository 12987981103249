import { useEffect, useState } from "react";
import axios from "axios";

import "./App.css";

import logo from "./logo/black.svg";
import logoWhite from "./logo/white.svg";

import Header from "./home/Header";
import Footer from "./home/Footer";

import TopEvent from "./home/TopEvent";
import BannerList from "./home/BannerList";
import TodayEvent from "./home/TodayEvent";
import Partner from "./home/Partner";

const API_SERVER = process.env.REACT_APP_API_URL;

const social = [
    {
        name: "YouTube",
        href: "#",
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },

    {
        name: "X",
        href: "#",
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
            </svg>
        ),
    },

    {
        name: "Facebook",
        href: "#",
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },

    {
        name: "Instagram",
        href: "#",
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },

    {
        name: "Telegram",
        href: "#",
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 13C22 19.0751 17.0751 24 11 24C4.92487 24 0 19.0751 0 13C0 6.92487 4.92487 2 11 2C17.0751 2 22 6.92487 22 13ZM11.3942 10.12068C10.3243 10.5657 8.18596 11.48676 4.97924 12.8839C4.45851 13.091 4.18574 13.2935 4.1609 13.4916C4.11894 13.8264 4.53815 13.9582 5.10901 14.1377C5.18667 14.1621 5.26712 14.1874 5.34961 14.2142C5.91125 14.3968 6.66676 14.6104 7.05951 14.6189C7.41578 14.6266 7.81342 14.4797 8.25242 14.1782C11.2486 12.1558 12.7952 11.13349 12.8923 11.11145C12.9608 11.09591 13.0557 11.07636 13.12 11.13352C13.1844 11.19069 13.178 11.29896 13.1712 11.328C13.1297 11.50504 11.4841 13.0349 10.6325 13.8266C10.367 14.0734 10.1787 14.2485 10.1402 14.2885C10.054 14.3781 9.96612 14.4628 9.88165 14.5442C9.35987 15.0472 8.96859 15.4244 9.90331 16.0404C10.3525 16.3364 10.712 16.5812 11.0705 16.8254C11.4622 17.0921 11.8528 17.3581 12.3581 17.6894C12.4869 17.7738 12.6099 17.8614 12.7296 17.9468C13.1854 18.2717 13.5949 18.5636 14.1007 18.5171C14.3946 18.49 14.6983 18.2136 14.8525 17.3893C15.2169 15.4412 15.9332 11.22025 16.0987 9.48089C16.1132 9.3285 16.095 9.13347 16.0803 9.04786C16.0657 8.96225 16.035 8.84027 15.9238 8.74997C15.792 8.64303 15.5885 8.62047 15.4975 8.62208C15.0838 8.62937 14.449 8.85008 11.3942 10.12068Z"
                    transform="scale(0.833) translate(1.5, 1.5)"
                />
            </svg>
        ),
    },
];

const footNavi = [
    { name: "이용약관", href: "/" },
    { name: "개인정보처리방침", href: "/" },
    { name: "고객센터", href: "/" },
    { name: "광고제휴문의", href: "/" },
];

const navigation = [
    { name: "Home", href: "/", selected: true },
    { name: "Sportsbook", href: "/sportsbook" },
    { name: "Casino", href: "/casino" },
];

function Home() {
    const [allSites, setAllSites] = useState([]);
    const [filteredSites, setFilteredSites] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("Home");

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await axios.get(`${API_SERVER}/sites`);
                setAllSites(response.data);
                setFilteredSites(response.data);
            } catch (error) {
                console.error("Error fetching sites:", error);
            }
        };
        fetchSites();
    }, []);

    useEffect(() => {
        // 선택된 카테고리에 따라 사이트 목록을 필터링
        const newFilteredSites = allSites.filter(
            (site) =>
                selectedCategory === "Home" ||
                site.category === selectedCategory
        );
        setFilteredSites(newFilteredSites);
    }, [selectedCategory, allSites]);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category); // 선택된 카테고리를 업데이트
    };

    return (
        <div>
            <Header
                logo={logo}
                navigation={navigation}
                onCategoryChange={handleCategoryChange}
            />

            {filteredSites.map(({ type, _id, title, items }) => {
                switch (type) {
                    case "topEvent":
                        return (
                            <TopEvent key={_id} title={title} banner={items} />
                        );

                    case "banner":
                        return (
                            <BannerList
                                key={_id}
                                title={title}
                                banner={items}
                            />
                        );

                    case "todayEvent":
                        return (
                            <TodayEvent
                                key={_id}
                                title={title}
                                banner={items}
                            />
                        );

                    case "partner":
                        return (
                            <Partner key={_id} title={title} banner={items} />
                        );

                    default:
                        console.log("error");
                        return null;
                }
            })}

            <Footer social={social} navigation={footNavi} logo={logoWhite} />
        </div>
    );
}

export default Home;
