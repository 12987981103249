import React from "react";

function Footer({ social, navigation, logo }) {
    return (
        <footer className="footer_box">
            <nav className="mx-auto flex max-w-screen-2xl items-center justify-between p-6 lg:px-8">
                <div className="flex flex-col lg:flex-1">
                    <span className="font-light text-xs">Contact with us</span>
                    <div className="flex space-x-6 py-3">
                        <div className="flex justify-center space-x-4 md:order-2">
                            {social.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-white hover:text-white"
                                >
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon
                                        className="h-7 w-7"
                                        aria-hidden="true"
                                    />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </nav>

            <nav className="content-nav mx-auto flex flex-col lg:flex-row max-w-screen-2xl lg:items-center justify-between p-2 px-6 lg:px-8">
                {/* Copyright */}
                <div className="order-3 lg:order-1 text-xs pr-10 pb-5">
                    <span>Copyright © 2024 Bet Today</span>
                </div>

                {/* Navigation */}
                <nav className="order-2 flex flex-row font-light text-xs pb-5">
                    {navigation.map((item, index) => (
                        <a
                            key={item.name}
                            href={item.href}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {index > 0 && (
                                <span className="px-3 font-extralight">|</span>
                            )}
                            {item.name}
                        </a>
                    ))}
                </nav>

                {/* Logo */}
                <div className="order-1 lg:order-3 lg:flex lg:flex-1 lg:justify-end lg:pb-5">
                    <a href="/" className="-m-1.5 p-1.5">
                        <img src={logo} alt="logo" />
                    </a>
                </div>
            </nav>
        </footer>
    );
}

export default Footer;
