import React, { useState, useEffect } from "react";
import axios from "axios";

const API_SERVER = process.env.REACT_APP_API_URL;

const ItemSelectionPopup = ({ isOpen, onClose, onSave, type }) => {
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const response = await axios.get(`${API_SERVER}/items`);
                const filteredItems = response.data.filter(
                    (item) => type === "All" || item.type === type
                );
                setAvailableItems(filteredItems);
            } catch (error) {
                console.error("Error fetching items:", error);
            }
        };

        if (isOpen) {
            fetchItems();
        }
    }, [isOpen, type]);

    const handleSelectItem = (item) => {
        setSelectedItems((prev) => {
            if (prev.find((selectedItem) => selectedItem._id === item._id)) {
                return prev.filter(
                    (selectedItem) => selectedItem._id !== item._id
                );
            } else {
                return [...prev, item];
            }
        });
    };

    const handleSave = () => {
        onSave(selectedItems);
        onCloseAndReset();
    };

    if (!isOpen) return null;

    const onCloseAndReset = () => {
        setSelectedItems([]);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-600 bg-opacity-50 h-full w-full">
            <div className="w-full max-w-7xl p-8 mx-auto bg-white rounded-lg shadow-xl">
                <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                    광고 선택하기 : {type}
                </h2>
                <div className="grid grid-cols-10 gap-4 py-2">
                    {availableItems.map((item) => (
                        <div
                            key={item._id}
                            onClick={() => handleSelectItem(item)}
                            className={`flex flex-col items-center border-2 cursor-pointer hover:border-indigo-500 transition duration-150 ease-in-out ${
                                selectedItems.find((si) => si._id === item._id)
                                    ? "border-indigo-500 bg-indigo-100"
                                    : "border-gray-200"
                            }`}
                        >
                            <img
                                src={item.imageURL}
                                alt={item.title}
                                className="object-cover w-40"
                            />
                            <span className="mt-2 text-sm font-bold text-gray-600">
                                {item.name}
                            </span>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end mt-4 space-x-4">
                    <button
                        onClick={handleSave}
                        className="px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition duration-300 ease-in-out"
                    >
                        저장
                    </button>
                    <button
                        onClick={onCloseAndReset}
                        className="px-4 py-2 text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-100 transition duration-300 ease-in-out"
                    >
                        닫기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ItemSelectionPopup;
