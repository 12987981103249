import React, { useState, useEffect } from "react";
import axios from "axios";

import logoWhite from "./logo/white.svg";
import SitesList from "./admin/SitesList";
import ItemsTable from "./admin/ItemsTable";
import ItemModal from "./admin/ItemModal";
import Footer from "./admin/Footer";

const API_SERVER = process.env.REACT_APP_API_URL;

function Admin() {
    const typeOptions = ["banner", "partner", "todayEvent", "topEvent"];
    const categoryOptions = ["Home", "Sportsbook", "Casino"];
    const initItemData = {
        name: "",
        type: "banner",
        category: "All",
        promotion: "",
        partner: "",
        href: "",
        image: null,
    };

    const [items, setItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [itemData, setItemData] = useState(initItemData);

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const response = await axios.get(`${API_SERVER}/items`);
            setItems(response.data);
        } catch (error) {
            console.error("Error fetching items:", error);
        }
    };

    const deleteItem = async (item) => {
        try {
            await axios.delete(`${API_SERVER}/items/${item._id}`);
            fetchItems();
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setItemData({
            ...itemData,
            [name]: files ? files[0] : value,
        });
    };

    const handleEdit = (item) => {
        setCurrentItem(item);
        setItemData({ ...item, image: null });
        setIsModalOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!itemData.name || !itemData.type || !itemData.category) {
            alert("이름, 타입, 카테고리는 필수 항목입니다.");
            return;
        }

        const formData = new FormData();
        Object.keys(itemData).forEach((key) => {
            formData.append(key, itemData[key]);
            console.log(key, itemData[key]);
            return;
        });

        try {
            if (currentItem) {
                await axios.put(
                    `${API_SERVER}/items/${currentItem._id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
            } else {
                await axios.post(`${API_SERVER}/items`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
            }
            fetchItems();
            setIsModalOpen(false);
            setCurrentItem(null);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <header>
                <div className="h-14 bg-red-900 text-white invisible hidden lg:visible lg:flex w-full">
                    <div className="self-center w-full text-center font-bold">
                        관리자 모드
                    </div>
                </div>
            </header>

            <div className="p-8">
                <ItemsTable
                    items={items}
                    handleEdit={handleEdit}
                    deleteItem={deleteItem}
                    setIsModalOpen={setIsModalOpen}
                    setItemData={setItemData}
                />

                <div className="border-b-2 border-dashed py-8 border-gray-300"></div>

                <SitesList typeOptions={typeOptions} />
            </div>
            <ItemModal
                isOpen={isModalOpen}
                itemData={itemData}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
                setIsModalOpen={setIsModalOpen}
                typeOptions={typeOptions}
                categoryOptions={categoryOptions}
            />
            <Footer logo={logoWhite} />
        </div>
    );
}

export default Admin;
