import React from "react";

function Footer({ logo }) {
    return (
        <footer className="bg-red-900 text-white pt-6 mt-10">
            <nav className="mx-auto flex flex-row max-w-screen-3xl items-center justify-between p-2 px-8">
                {/* Copyright */}
                <div className="order-3 lg:order-1 text-xs pr-10 pb-5">
                    <span>Copyright © 2024 Bet Today</span>
                </div>

                {/* Logo */}
                <div className="order-1 lg:order-3 lg:flex lg:flex-1 lg:justify-end lg:pb-5">
                    <a
                        href="/"
                        target="_blank"
                        rel="noreferrer"
                        className="-m-1.5 p-1.5"
                    >
                        <img src={logo} alt="logo" />
                    </a>
                </div>
            </nav>
        </footer>
    );
}

export default Footer;
