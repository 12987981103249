import React from "react";

function TopEvent({ title, banner }) {
    return (
        <div className="py-2 sm:py-4 xl:mx-auto xl:max-w-screen-2xl">
            <div className="py-8 mx-auto flex flex-col sm:flex-row max-w-screen-2xl items-center justify-between gap-x-10 gap-y-6 px-4 lg:px-8 pt-2 lg:pt-4 lg:pb-14">
                {/* 모바일 화면에서는 전체 너비를 차지하도록 수정 */}
                <div className="flex-grow sm:basis-9/12 w-full rounded-2xl relative">
                    <img
                        className="h-108 w-full object-cover rounded-2xl"
                        src="/today/todayEvent.png"
                        alt=""
                    />
                    <div className="absolute top-0 left-0 right-0 px-5 py-3 text-white">
                        <span className="text-sm">
                            인증은 기본. 보너스는 시원하게. 베팅은 통크게
                        </span>
                        <div className="text-4xl font-bold">{title}</div>
                    </div>
                </div>

                {/* 모바일 화면에서는 전체 너비를 차지하도록 수정 */}
                <div className="flex flex-col w-full sm:basis-3/12 h-108 justify-between bg-white rounded-2xl shadow-xl shadow-gray-200">
                    <div
                        id="top-main-event"
                        className="flex-grow h-1/2 bg-gray-300 rounded-t-2xl relative"
                    >
                        <img
                            className="h-full w-full object-cover rounded-t-2xl"
                            src="/today/todayRecommend.png"
                            alt=""
                        />
                        <div className="absolute top-0 left-0 right-0 px-5 py-3">
                            <span className="text-sm">
                                인증은 기본. 보너스는 시원하게. 베팅은 통크게
                            </span>
                            <div className="text-4xl font-bold">
                                오늘의 추천
                            </div>
                        </div>
                    </div>
                    <div id="top-sub-event" className="flex flex-col p-5">
                        <span className="font-bold">{banner[1].name}</span>
                        <span className="text-sm text-gray-500">
                            {banner[1].promotion}
                        </span>
                    </div>
                </div>
            </div>
            <hr className="invisible lg:visible mx-8"></hr>
        </div>
    );
}

export default TopEvent;
